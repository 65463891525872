<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>{{ 'dashboard.title' | translate }}</h5>
      <div class="card">
        <app-competitions></app-competitions>
      </div>
      <div class="card">
        <app-users></app-users>
      </div>
    </div>
  </div>
</div>
