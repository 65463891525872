import { OnInit, Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../auth/auth.service';
import { LayoutService } from '../service/app.layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  model: MenuItem[] = [];

  constructor(
    public layoutService: LayoutService,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.model = [
      {
        label: 'Main',
        items: [
          { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
        ]
      }
    ];
    // if (this.authService.loggedInUser?.role === 1) {
    //   this.model.push({
    //     label: 'Users',
    //     items: [
    //       {
    //         label: 'Manage app users',
    //         icon: 'pi pi-fw pi-user',
    //         routerLink: ['users']
    //       }
    //     ]
    //   });
    // }

    // this.model = [
    //   {
    //     label: 'Main',
    //     items: [
    //       { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
    //     ]
    //   },
    //   {
    //     label: 'Users',
    //     items: [
    //       {
    //         label: 'Manage app users',
    //         icon: 'pi pi-fw pi-user',
    //         routerLink: ['users']
    //       }
    //     ]
    //   }
    // {
    //     label: 'Utilities',
    //     items: [
    //         { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['/utilities/icons'] },
    //         { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank' },
    //     ]
    // },
    // {
    //     label: 'Pages',
    //     icon: 'pi pi-fw pi-briefcase',
    //     items: [
    //         {
    //             label: 'Landing',
    //             icon: 'pi pi-fw pi-globe',
    //             routerLink: ['/landing']
    //         },
    //         {
    //             label: 'Auth',
    //             icon: 'pi pi-fw pi-user',
    //             items: [
    //                 {
    //                     label: 'Login',
    //                     icon: 'pi pi-fw pi-sign-in',
    //                     routerLink: ['/auth/login']
    //                 },
    //                 {
    //                     label: 'Error',
    //                     icon: 'pi pi-fw pi-times-circle',
    //                     routerLink: ['/auth/error']
    //                 },
    //                 {
    //                     label: 'Access Denied',
    //                     icon: 'pi pi-fw pi-lock',
    //                     routerLink: ['/auth/access']
    //                 }
    //             ]
    //         },
    //         {
    //             label: 'Crud',
    //             icon: 'pi pi-fw pi-pencil',
    //             routerLink: ['/pages/crud']
    //         },
    //         {
    //             label: 'Timeline',
    //             icon: 'pi pi-fw pi-calendar',
    //             routerLink: ['/pages/timeline']
    //         },
    //         {
    //             label: 'Not Found',
    //             icon: 'pi pi-fw pi-exclamation-circle',
    //             routerLink: ['/notfound']
    //         },
    //         {
    //             label: 'Empty',
    //             icon: 'pi pi-fw pi-circle-off',
    //             routerLink: ['/pages/empty']
    //         },
    //     ]
    // },
    // {
    //     label: 'Hierarchy',
    //     items: [
    //         {
    //             label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
    //             items: [
    //                 {
    //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
    //                     items: [
    //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
    //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
    //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
    //                     ]
    //                 },
    //                 {
    //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
    //                     items: [
    //                         { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }
    //                     ]
    //                 },
    //             ]
    //         },
    //         {
    //             label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
    //             items: [
    //                 {
    //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
    //                     items: [
    //                         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
    //                         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
    //                     ]
    //                 },
    //                 {
    //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
    //                     items: [
    //                         { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
    //                     ]
    //                 },
    //             ]
    //         }
    //     ]
    // },
    // {
    //     label: 'Get Started',
    //     items: [
    //         {
    //             label: 'Documentation', icon: 'pi pi-fw pi-question', routerLink: ['/documentation']
    //         },
    //         {
    //             label: 'View Source', icon: 'pi pi-fw pi-search', url: ['https://github.com/primefaces/sakai-ng'], target: '_blank'
    //         }
    //     ]
    // }
    // ];
  }
}
