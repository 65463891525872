import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { CoreService } from './core/core.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private coreService: CoreService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.translateService.use('ro');
    this.translateService.defaultLang = 'ro';
    this.primengConfig.ripple = false;
    const healthy = this.coreService.apiHealthCheck();
    if (!healthy) {
      this.router.navigate(['maintenance']).catch(error => console.log(error));
    }
  }
}
