import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from './users.types';
import { CoreService } from '../core/core.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly coreService: CoreService
  ) {}

  public getAllUsers(): Observable<User[]> {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Users/0`)
      .pipe(map(item => this.coreService.parseDataFromNode(item)));
  }
  public getUserById(id: string): Observable<User> {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Users/${id}`)
      .pipe(map(item => this.coreService.parseDataFromNode(item)));
  }

  public updateUser(user: User) {
    return this.httpClient.put(
      `${this.coreService.apiUrl}/api/Users/${user.id}`,
      user
    );
  }

  public createUser(user: Partial<User>) {
    return this.httpClient
      .post(`${this.coreService.apiUrl}/api/Users/`, user)
      .pipe(map(item => this.coreService.parseDataFromNode(item)));
  }

  public deleteUser(user: Partial<User>) {
    return this.httpClient.delete(
      `${this.coreService.apiUrl}/api/Users/${user.id}`
    );
  }
}
