<p-card
  header="{{ !(roleName === 'Referee' || roleName === 'Limited Referee') ? 'competitions.modal.competitionDetailTitle' : 'competitions.modal.refereeCompetitionDetailTitle' | translate  }}"
  class="min-h-screen">
<!--  <p-toast></p-toast>-->
  <div class="flex" style="gap: 10px">
    <button style="width: 25%; display: flex; justify-content: center" class="mb-2" pButton (click)="closeParentModal()">
      Inapoi
    </button>
    <button style="width: 25%; display: flex; justify-content: center" class="mb-2" pButton severity="info" (click)="openRankDialog()">
      Clasament
    </button>
    <button style="width: 25%; display: flex; justify-content: center" class="mb-2" pButton severity="info" (click)="openCmmcDialog()">
      Cea mai mare captura / specie
    </button>
  </div>
  <div class="grid" *ngIf="roleName !== 'Referee' && roleName !== 'Limited Referee'">
    <div style="width: 100%">
      <div class="row flex">
        <div class="field col">
          <div class="field" *ngIf="competition.description">
            <label for="description">{{ 'competitions.description' | translate }}</label>
            <p-editor [ngModel]="competition.description" [style]="{ height: '320px' }" id="description"></p-editor>
          </div>
        </div>
        <div class="field col">
          <p>Banner competitie</p>
          <div class="field">
            <div *ngIf="competition.s3Data">
              <p-image [src]="S3Url + '/' + competition.s3Data" height="250" width="auto" [alt]="competition.s3Data"
                       [preview]="true" />
            </div>
            <div>
              <p-fileUpload name="profile" [customUpload]="true" [multiple]="false" [auto]="true" accept="image/*"
                            mode="basic"
                            chooseLabel="Alege banner competitie" (uploadHandler)="onCompetitionImgUpload($event)">
              </p-fileUpload>
            </div>
          </div>

        </div>
      </div>


      <div class="p-formgrid grid" [formGroup]="competitionForm">
        <div class="field col">
          <div class="field">
            <label for="startDate">{{ 'competitions.startDate' | translate }}</label>
            <p-calendar id="startDate" [showTime]="true" [showIcon]="true" formControlName="startDate"
                        [ngClass]="{'ng-invalid ng-dirty' : competitionForm.controls['startDate'].touched && competitionForm.controls['startDate'].invalid}"></p-calendar>
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['startDate'].touched && competitionForm.controls['startDate'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field">
            <label for="endDate">{{ 'competitions.endDate' | translate }}</label>
            <p-calendar id="endDate" [showTime]="true" [showIcon]="true" formControlName="endDate"
                        [ngClass]="{'ng-invalid ng-dirty' : competitionForm.controls['endDate'].touched && competitionForm.controls['endDate'].invalid}"></p-calendar>
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['endDate'].touched && competitionForm.controls['endDate'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field">
            <label for="competitionName">{{ 'competitions.competitionName' | translate }}</label>
            <input type="text" pInputText id="competitionName" formControlName="competitionName" required
                   [ngClass]="{'ng-invalid ng-dirty' : competitionForm.controls['competitionName'].touched && competitionForm.controls['competitionName'].invalid}" />
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['competitionName'].touched && competitionForm.controls['competitionName'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
        </div>
        <div class="field col">
          <div class="field">
            <label for="referees">{{ 'competitions.selectReferees' | translate }}</label>
            <p-multiSelect id="referees" [options]="refereeOptions" formControlName="referees"
                           placeholder="{{ 'competitions.selectReferees' | translate }}"
                           [showClear]="true"
                           [class]="competitionForm.controls['referees'].touched && competitionForm.controls['referees'].invalid ? 'ng-invalid ng-dirty' : ''"
            ></p-multiSelect>
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['referees'].touched && competitionForm.controls['referees'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field">
            <label for="participants">{{ 'competitions.selectParticipants' | translate }}</label>
            <p-multiSelect id="participants" [options]="participantsOptions" formControlName="competitors"
                           placeholder="{{ 'competitions.selectParticipants' | translate }}"
                           [showClear]="true"
            ></p-multiSelect>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 25%">
      <button style="display: flex; justify-content: center" pButton (click)="updateCompetition()">Actualizare
        competitie
      </button>
    </div>
  </div>
  <div class="grid" *ngIf="roleName === 'Referee' || roleName === 'Limited Referee'">
    <div class="flex-column col-12 gap-2x">
      <p-messages *ngIf="newCapturesLoaded" [(value)]="newCaptureLoadedMessages" />
      <p-messages *ngIf="noNewCaptures" [(value)]="noNewCapturesMessages" />
      <p-dataView #dv [value]="filteredCaptures" [paginator]="true" [rows]="15" layout="grid" [sortField]="sortField" [sortOrder]="sortOrder"
                  emptyMessage="{{ (activeFilter === 'All' ?
                   'captures.noResults' : activeFilter === 'Pending' ?
                    'captures.noPendingResults' : activeFilter === 'Approved' ?
                     'captures.noApprovedResults' : activeFilter === 'Rejected' ?
                      'captures.noRejectedResults' : activeFilter === 'Debate' ?
                       'captures.noDebateResults' : 'captures.noResults') | translate }}">
        <ng-template pTemplate="header">
          <div class="flex flex-wrap justify-content-start align-items-center gap-2">
            <p-button [loading]="updateLoading" size="small" label="Actualizare" severity="success" class="m-2 flex-grow-1 flex-22"
                      (click)="updateCaptures()"></p-button>
            <p-multiSelect [options]="getUniqueUsers()" [filter]="true" (onChange)="applyFilters()" [(ngModel)]="filterTeam" placeholder="Alege un participant" optionLabel="userName" class="multiselect-custom flex-22 m-2" display="chip">
              <ng-template let-capture pTemplate="item">
                <div class="flex align-items-center">
                  <span class="ml-2">{{capture.userName}}</span>
                </div>
              </ng-template>
            </p-multiSelect>
            <p-multiSelect [options]="getUniqueSpecies()" [filter]="true" (onChange)="applyFilters()" [disabled]="filterTeam.length === 0" [(ngModel)]="filterSpecies" placeholder="Alege o specie" optionLabel="fishType" class="multiselect-custom flex-22 m-2" display="chip">
              <ng-template let-capture pTemplate="item">
                <div class="flex align-items-center">
                  <span class="ml-2">{{capture.fishType}}</span>
                </div>
              </ng-template>
            </p-multiSelect>
            <p-dropdown [options]="sortOptions" [(ngModel)]="activeSort" placeholder="Sorteaza dupa lungime" showClear (onChange)="onSortChange($event)"></p-dropdown>
            <p-dropdown *ngIf="dayFilterOptions.length" [options]="dayFilterOptions" showClear placeholder="Filtreaza dupa zi" [(ngModel)]="activeDayFilter" (onChange)="onDayChange($event)"></p-dropdown>
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
          </div>
          <div class="flex flex-wrap">
            <p-button [disabled]="activeFilter === 'Pending'" severity="warning" class="m-2 flex-25 flex-grow-1" size="small" label="{{ 'Pending' | translate}}"
                      (click)="applyFilters('Pending')">
            </p-button>
            <p-button [disabled]="activeFilter === 'Rejected'" severity="danger" class="m-2 flex-25 flex-grow-1" size="small" label="{{ 'Rejected' | translate }}"
                      (click)="applyFilters('Rejected')">
            </p-button>
            <p-button [disabled]="activeFilter === 'Debate'" severity="help" class="m-2 flex-25 flex-grow-1"
                      (click)="applyFilters('Debate')" size="small" label="{{ 'Contestatie' | translate }}">
            </p-button>
            <p-button [disabled]="activeFilter === 'Approved'" severity="success" class="m-2 flex-25 flex-grow-1"
                      (click)="applyFilters('Approved')" size="small" label="{{ 'Aprobat' | translate }}">
            </p-button>
            <p-button severity="info" [disabled]="activeFilter === 'All'" class="m-2 flex-25 flex-grow-1" size="small" label="{{ 'Toate' | translate }}"
                      (click)="applyFilters('All')">
            </p-button>

          </div>


        </ng-template>
        <ng-template let-captures pTemplate="listItem">
          <div class="col-12" *ngFor="let element of captures; trackBy: trackById">
            <div class="card p-2 m-1 border-1 surface-border">
              <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
                <div class="flex align-items-center">
                  <i class="pi pi-tag mr-2"></i>
                  <span class="font-semibold">{{ element.userName }}</span>
                </div>
                <span *ngIf="!isInDebate(element)"
                      [class]="'product-badge status-' + element.status.toLowerCase()">{{ element.status | translate }}</span>
                <span *ngIf="isInDebate(element)" [class]="'order-badge order-returned'">{{ 'Contestatie' }}</span>
              </div>
              <div class="flex mb-2 align-items-center" style="justify-content: flex-end">
                <p *ngIf="(!!rejectionMessage && element.status === 'Rejected') || (!!element.statusDetails && element.status === 'Rejected')" style="margin-right: 4px">
                  <b>Motiv</b>: {{ element.statusDetails }}
                </p>
                <p *ngIf="!element.statusDetails && element.status !== 'Rejected'" style="visibility: hidden;"> <b>Motivare status</b>: Placeholder </p>
              </div>
              <div class="flex justify-content-evenly">
                <p-image [src]="S3Url + '/' + element.s3Data" height="250" width="100%" [alt]="element.s3Data"
                         [preview]="true" (onShow)="onPreviewOpen($event)" loading="lazy"/>
                <div class="flex flex-column">
                  <div class="text-2xl font-bold mb-3">{{ element.fishType }}</div>
                  <div class="mb-3">
                    <p-inputGroup class="mb-1">
                      <input type="text" [readOnly]="roleName !== 'Referee'" pInputText placeholder="Keyword" [(ngModel)]="element.length" />
                      <button type="button" pButton label="Modifica" [disabled]="roleName !== 'Referee'"
                              (click)="updateLength(element.id, element.length)"></button>
                    </p-inputGroup>
                    <p-inputGroup>
                      <p-dropdown class="mr-1" [readonly]="roleName !== 'Referee'" [options]="competitionSpecies" [(ngModel)]="element.fishType"></p-dropdown>
                      <button type="button" pButton label="Modifica" [disabled]="roleName !== 'Referee'"
                              (click)="updateSpecies(element.id, element.fishType)"></button>
                    </p-inputGroup>
                  </div>
                  <div class="flex align-items-center justify-content-between">
                    <p-button *ngIf="element.status !== 'Approved'" icon="pi pi-check-square" [disabled]="roleName !== 'Referee'" severity="success"
                              (click)="updateCaptureStatus(element.id, 'Approved')"></p-button>
                    <span>{{ element.createDate + 'Z' | date:'dd.MM.YYYY, HH:mm' }}</span>
                    <p-button [disabled]="roleName !== 'Referee'" *ngIf="element.status !== 'Rejected'" icon="pi pi-times" severity="danger"
                              (click)="updateCaptureStatus(element.id, 'Rejected')"></p-button>
                  </div>
                  <div class="flex align-items-center justify-content-evenly" style="margin-top: 8px">
                    <div class="card mb-0 col-12" *ngIf="isInDebate(element)">
                      <div class="flex justify-content-between mb-3">
                        <div>
                          <span class="block text-xl text-900 font-medium mb-3">Contestatie activa</span>
                          <span class="text-500 font-bold">{{ getDebateCreator(element) }}: </span>
                          <span class="text-500 font-medium" style="word-break: break-word">{{ getDebateClaim(element) }}</span>
                        </div>

                        <div class="flex">
                          <p-button icon="pi pi-thumbs-up" severity="success" [disabled]="roleName !== 'Referee'"
                                    class="flex align-items-center justify-content-center border-round m-2"
                                    [ngStyle]="{width: '3rem', height: '2.5rem'}" (click)="debateAccepted(element)">
                          </p-button>
                          <p-button icon="pi pi-ban" severity="danger" [disabled]="roleName !== 'Referee'"
                                    class="flex align-items-center justify-content-center border-round m-2"
                                    [ngStyle]="{width: '3rem', height: '2.5rem'}" (click)="debateDeclined(element)">
                          </p-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
<!--            <div class="flex flex-column md:flex-row align-items-center p-3 w-full">-->
<!--              <img [src]="'assets/demo/images/product/' + element.image" [alt]="element.name" class="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5"/>-->
<!--              <div class="flex-1 flex flex-column align-items-center text-center md:text-left">-->
<!--                <div class="font-bold text-2xl">{{element.name}}</div>-->
<!--                <div class="mb-2">{{element.description}}</div>-->
<!--&lt;!&ndash;                <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false" styleClass="mb-2"></p-rating>&ndash;&gt;-->
<!--                <div class="flex align-items-center mt-2">-->
<!--                  <i class="pi pi-tag mr-2"></i>-->
<!--                  <span class="font-semibold">{{element.category}}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">-->
<!--                <span class="text-2xl font-semibold mb-2 align-self-center md:align-self-end">${{element.price}}</span>-->
<!--                <p-button icon="pi pi-shopping-cart" label="Add to Cart" [disabled]="element.inventoryStatus === 'OUTOFSTOCK'" styleClass="mb-2 p-button-sm"></p-button>-->
<!--                <span [class]="'product-badge status-' + element.inventoryStatus.toLowerCase()">{{element.inventoryStatus}}</span>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </ng-template>
        <ng-template let-captures pTemplate="gridItem">
          <div class="grid grid-nogutter">
            <div class="col-12 lg:col-4" *ngFor="let element of captures; trackBy: trackById">
              <div class="card p-2 m-1 border-1 surface-border">
                <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
                  <div class="flex align-items-center">
                    <i class="pi pi-tag mr-2"></i>
                    <span class="font-semibold">{{ element.userName }}</span>
                  </div>
                  <span *ngIf="!isInDebate(element)"
                        [class]="'product-badge status-' + element.status.toLowerCase()">{{ element.status | translate }}</span>
                  <span *ngIf="isInDebate(element)" [class]="'order-badge order-returned'">{{ 'Contestatie' }}</span>
                </div>
                <div class="flex mb-2 align-items-center" style="justify-content: flex-end">
                  <p *ngIf="(!!rejectionMessage && element.status === 'Rejected') || (!!element.statusDetails && element.status === 'Rejected')" style="margin-right: 4px">
                    <b>Motiv</b>: {{ element.statusDetails }}
                  </p>
                  <p *ngIf="!element.statusDetails && element.status !== 'Rejected'" style="visibility: hidden;"> <b>Motivare status</b>: Placeholder </p>
                </div>
                <div class="flex nowrap flex-column justify-content-center text-center">
                  <p-image [src]="S3Url + '/' + element.s3Data" height="250" width="100%" [alt]="element.s3Data"
                           [preview]="true" (onShow)="onPreviewOpen($event)" loading="lazy"/>
                  <div class="text-2xl font-bold mb-3">{{ element.fishType }}</div>
                  <div class="mb-3">
                    <p-inputGroup class="mb-1">
                      <input type="text" [readOnly]="roleName !== 'Referee'" pInputText placeholder="Keyword" [(ngModel)]="element.length" />
                      <button type="button" pButton label="Modifica" [disabled]="roleName !== 'Referee'"
                              (click)="updateLength(element.id, element.length)"></button>
                    </p-inputGroup>
                    <p-inputGroup>
                      <p-dropdown class="mr-1" [readonly]="roleName !== 'Referee'" [options]="competitionSpecies" [(ngModel)]="element.fishType"></p-dropdown>
                      <button type="button" [disabled]="roleName !== 'Referee'" pButton label="Modifica"
                              (click)="updateSpecies(element.id, element.fishType)"></button>
                    </p-inputGroup>
                  </div>
                </div>
                <div class="flex align-items-center justify-content-between">
                  <p-button *ngIf="element.status !== 'Approved'" [disabled]="roleName !== 'Referee'" icon="pi pi-check-square" severity="success"
                            (click)="updateCaptureStatus(element.id, 'Approved')"></p-button>
                  <span>{{ element.createDate + 'Z' | date:'dd.MM.YYYY, HH:mm' }}</span>
                  <p-button *ngIf="element.status !== 'Rejected'" [disabled]="roleName !== 'Referee'" icon="pi pi-times" severity="danger"
                            (click)="updateCaptureStatus(element.id, 'Rejected')"></p-button>
                </div>
                <div class="flex align-items-center justify-content-evenly" style="margin-top: 8px">
                  <div class="card mb-0 col-12" *ngIf="isInDebate(element)">
                    <div class="flex justify-content-between mb-3">
                      <div>
                        <span class="block text-xl text-900 font-medium mb-3">Contestatie activa</span>
                        <span class="text-500 font-bold">{{ getDebateCreator(element) }}: </span>
                        <span class="text-500 font-medium" style="word-break: break-word">{{ getDebateClaim(element) }}</span>
                      </div>

                      <div class="flex">
                        <p-button icon="pi pi-thumbs-up" severity="success" [disabled]="roleName !== 'Referee'"
                                  class="flex align-items-center justify-content-center border-round m-2"
                                  [ngStyle]="{width: '3rem', height: '2.5rem'}" (click)="debateAccepted(element)">
                        </p-button>
                        <p-button icon="pi pi-ban" severity="danger" [disabled]="roleName !== 'Referee'"
                                  class="flex align-items-center justify-content-center border-round m-2"
                                  [ngStyle]="{width: '3rem', height: '2.5rem'}" (click)="debateDeclined(element)">
                        </p-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>

</p-card>
<p-dialog #competitionRanking [(visible)]="rankingDialogOpen" [style]="{width: '800px'}" [modal]="true" [closable]="true" class="p-fluid">
  <ng-template pTemplate="content">
    <div class="flex gap-4">
      <button pButton class="flex justify-content-center" (click)="activeTable('General')" severity="info">General</button>
      @if (!!additionalRanking) {
        @for (item of additionalRanking; track item; let index = $index) {
          <button class="flex justify-content-center" pButton (click)="activeTable('', index)" severity="info">{{ item.breedName }}</button>
        }
      }
    </div>
    <p-table *ngIf="table === 'General'" #table [value]="ranking" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" dataKey="teamName">
      <ng-template pTemplate="header">
        <tr>
          <th>#</th>
          <th>Participant</th>
          <th>Scor</th>
          <th>Bonus</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rank>
        <tr>
          <td>
            {{ rank.position }}
          </td>
          <td>
            {{ rank.teamName }}
          </td>
          <td>
            {{ rank.teamScore }}
          </td>
          <td>
            {{ rank.bonusPoints }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-table *ngIf="table !== 'General'" #table [value]="additionalRanking[activeIndex]?.breedRanking" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" dataKey="teamName">
      <ng-template pTemplate="header">
        <tr>
          <th>#</th>
          <th>Participant</th>
          <th>Scor</th>
          <th>Bonus</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rank>
        <tr>
          <td>
            {{ rank.position }}
          </td>
          <td>
            {{ rank.teamName }}
          </td>
          <td>
            {{ rank.teamScore }}
          </td>
          <td>
            {{ rank.bonusPoints }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-dialog>
<p-dialog #cmmcDialog [(visible)]="cmmcDialogOpen" [style]="{width: '1000px'}" modal closable class="p-fluid">
  <ng-template pTemplate="content">
    <div class="surface-ground">
      <div class="grid">
        @if (cmmcData?.length) {
          @for (item of cmmcData; track item) {
            <div class="col-12 md:col-6 lg:col-3">
              <div class="surface-card shadow-2 border-round w-100">
                <div class="flex flex-column align-items-center mb-3">

                    <div class="text-500 font-medium mb-3">{{ item.breedName }}</div>
                    <div class="text-900 font-medium text-xl mb-3">{{ item?.maxLength }}</div>
                    <div class="text-500 font-medium mb-3" style="word-break: break-word;">{{ item.teamName }}</div>
                </div>
              </div>
            </div>
          }
        }

      </div>
    </div>

  </ng-template>
</p-dialog>

<p-dialog #confirmRejectDebate [(visible)]="confirmRejectDebateDialogOpen" [style]="{width: '800px', height: '400px'}" [modal]="true" [closable]="true" class="p-fluid">
  <ng-template pTemplate="content">
    <div class="flex h-100" style="flex-direction: column; justify-content: space-between;">
      <div class="field">
        <label for="rejectionMessage">Detaliile respingerii</label>
        <p-dropdown id="rejectionMessage" [(ngModel)]="rejectionMessage" [options]="[{value: 'Duplicat', label: 'Duplicat'}, { value: 'Poza incompleta/invalida', label: 'Poza incompleta/invalida'}, { value: 'Altele', label: 'Altele'}]" />
      </div>
      <div class="flex gap-2">
        <button pButton pRipple [disabled]="!rejectionMessage" label="Respinge captura" class="p-button" severity="danger" (click)="updateCaptureStatus(selectedCaptureId!, 'Rejected', true)"></button>
        <button pButton pRipple label="Inchide" class="p-button" severity="primary" (click)="cancelRejection()"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
