<div class="grid">
  <div class="col-12">
      <p-toast></p-toast>
      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
          <h5 class="m-0">{{ 'users.title' | translate }}</h5>
        </ng-template>
      </p-toolbar>

      <p-table #dt [value]="users" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [rowsPerPageOptions]="rowsPerPageOptions" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [(selection)]="selectedUsers" selectionMode="multiple" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div class="my-2">
              <button pButton pRipple label="{{ 'users.addUserButton' | translate }}" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="firstName">{{ 'users.firstName' | translate }} <p-sortIcon field="firstName"></p-sortIcon></th>
            <th pSortableColumn="lastName">{{  'users.lastName' | translate }} <p-sortIcon field="lastName"></p-sortIcon></th>
            <th pSortableColumn="email">{{ 'users.email' | translate }} <p-sortIcon field="email"></p-sortIcon></th>
            <th pSortableColumn="role">{{ 'users.role' | translate }} <p-sortIcon field="role"></p-sortIcon></th>
            <th pSortableColumn="active">{{  'users.active' | translate }} <p-sortIcon field="active"></p-sortIcon></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td style="width:14%; min-width:10rem;">
              <span class="p-column-title">{{ 'users.firstName' | translate }}</span>
              {{ user.firstName }}
            </td>
            <td style="width:14%; min-width:8rem;">
              <span class="p-column-title">{{ 'users.lastName' | translate }}</span>
              {{ user.lastName }}
            </td>
            <td style="width:14%; min-width:10rem;">
              <span class="p-column-title">{{ 'users.email' | translate }}</span>
              {{ user.email }}
            </td>
            <td style="width:14%; min-width:10rem;">
              <span class="p-column-title">{{ 'users.role' | translate }}</span>
              {{ userRoleName(user) }}
            </td>
            <td style="width:5%; min-width: 5rem;"><span class="p-column-title">{{ 'users.active' | translate }}</span>
              <span *ngIf="user.active"><p-badge severity="success"></p-badge> </span>
              <span *ngIf="user.active === false"><p-badge severity="danger"></p-badge> </span>
            </td>
            <td>
              <div class="flex">
                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editUser(user)"></button>
                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteUser(user)"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    <p-dialog [maximizable]="true" [closable]="false" [(visible)]="userDialog" [style]="{width:'600px'}" header="{{ 'users.modal.userTitle' | translate}}" [modal]="true" class="p-fluid">
      <ng-template pTemplate="content">
        <form [formGroup]="userForm">
          <div class="field">
            <label for="role" class="block font-medium mb-2">{{ 'users.selectRole' | translate }}</label>
            <p-dropdown [options]="userRoles" formControlName="role" class="text-900" id="role"
                        placeholder="{{ 'users.selectRole' | translate }}"
                        [showClear]="true"></p-dropdown>
          </div>
          <div class="field">
            <label for="firstName">{{ 'users.firstName' | translate }}</label>
            <input type="text" pInputText id="firstName" formControlName="firstName" required [ngClass]="{'ng-invalid ng-dirty' : userForm.controls['firstName'].touched && userForm.controls['firstName'].invalid}" />
            <small class="ng-dirty ng-invalid" *ngIf="userForm.controls['firstName'].touched && userForm.controls['firstName'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field">
            <label for="lastName">{{ 'users.lastName' | translate }}</label>
            <input type="text" pInputText formControlName="lastName" id="lastName" required [ngClass]="{'ng-invalid ng-dirty' : userForm.controls['lastName'].touched && userForm.controls['lastName'].invalid}" />
            <small class="ng-dirty ng-invalid" *ngIf="userForm.controls['lastName'].touched && userForm.controls['lastName'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field">
            <label for="middleName">{{ 'users.middleName' | translate }}</label>
            <input type="text" pInputText formControlName="middleName" id="middleName" />
          </div>
          <div class="field">
            <label for="email">{{ 'users.email' | translate }}</label>
            <input type="text" pInputText formControlName="email" id="email" required [ngClass]="{'ng-invalid ng-dirty' : userForm.controls['email'].touched && userForm.controls['email'].invalid}" />
            <small class="ng-dirty ng-invalid" *ngIf="userForm.controls['email'].touched && userForm.controls['email'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field" style="display: flex; align-items: center">
            <label for="active" style="margin-right: 4px">{{ 'users.active' | translate }}</label>
            <p-inputSwitch formControlName="active" id="active" />
          </div>
        </form>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="{{ 'users.form.cancel' | translate}}" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="{{ 'users.form.save' | translate }}" icon="pi pi-check" [disabled]="userForm.invalid" class="p-button-text" (click)="saveUser()"></button>
      </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="deleteUsersDialog" header="{{ 'users.modal.deleteTitle' | translate }}" [modal]="true" [style]="{width:'450px'}">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="user">{{ 'users.modal.deleteMessage' | translate }} <b>{{user.firstName}} {{ user.lastName }}</b>?</span>
      </div>
      <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{ 'users.form.decline' | translate}}" (click)="deleteUsersDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="p-button-text" label="{{ 'users.form.accept' | translate}}" (click)="confirmDelete()"></button>
      </ng-template>
    </p-dialog>
  </div>
</div>
