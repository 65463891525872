import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { authGuard } from './auth/auth.guard';
import { ProfileComponent } from './auth/profile/profile.component';
import { NotFoundComponent } from './core/notfound/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { UsersComponent } from './users/users.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          canActivate: [authGuard],
          component: LayoutComponent,
          children: [
            {
              path: '',
              component: DashboardComponent
            },
            {
              path: 'profile',
              component: ProfileComponent
            },
            {
              path: 'users',
              component: UsersComponent
            }
          ]
        },
        {
          path: 'login',
          loadChildren: () =>
            import('./auth/auth.module').then(m => m.AuthModule)
        },
        { path: 'not-found', component: NotFoundComponent },
        { path: '**', redirectTo: '/not-found' }
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
