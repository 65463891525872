import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CoreService } from '../core/core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly coreService: CoreService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method === 'GET' && req.url.includes('Login')) {
      return next.handle(req);
    } else if (req.method === 'POST' && req.url.includes('Login')) {
      const authReq = req.clone({
        setHeaders: {
          'FS-ApiKey': this.coreService.apiKey
        }
      });
      return next.handle(authReq);
    } else {
      const authToken = this.authService.appState?.profile?.token;
      const authReq = req.clone({
        setHeaders: {
          'FS-AuthToken': `${authToken}`,
          'FS-ApiKey': this.coreService.apiKey
        }
      });

      return next.handle(authReq);
    }
  }
}
