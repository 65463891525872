import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { UsersService } from './users.service';
import { User } from './users.types';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit {
  public userDialog: boolean = false;
  public deleteUsersDialog: boolean = false;
  public userRoles: SelectItem[] = [];
  public users: User[] = [];
  public user: Partial<User> = {};
  public userForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, { validators: Validators.required }),
    lastName: new FormControl(null, { validators: Validators.required }),
    middleName: new FormControl(null),
    email: new FormControl(null, { validators: Validators.required }),
    role: new FormControl(null, { validators: Validators.required }),
    active: new FormControl(null)
  });
  public selectedUsers: User[] = [];
  public cols: any[] = [];
  public statuses: any[] = [];
  public rowsPerPageOptions = [5, 10, 20];

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.usersService.getAllUsers().subscribe(res => (this.users = res));
    this.authService.getAvailableRoles().subscribe(res => {
      this.userRoles = res.map((item: any) => ({
        value: item.id,
        label: item.roleName
      }));
    });
    this.cols = [
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'email', header: 'Email' },
      { field: 'role', header: 'Role' },
      { field: 'active', header: 'Status' }
    ];

    this.statuses = [{ label: 'active', value: 'true' }];
  }

  public userRoleName(user: User) {
    return this.userRoles.find((item: any) => item.value === user.role)?.label;
  }

  openNew() {
    this.user = {};
    this.userDialog = true;
  }

  editUser(user: Partial<User>) {
    this.user = { ...user };
    this.userForm.patchValue({
      ...user
    });
    this.userDialog = true;
  }

  deleteUser(user: Partial<User>) {
    this.deleteUsersDialog = true;
    this.user = { ...user };
  }

  confirmDelete() {
    this.usersService.deleteUser(this.user).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'User Deleted',
        life: 3000
      });
      this.deleteUsersDialog = false;
      this.user = {};
    });
  }

  hideDialog() {
    this.userDialog = false;
    this.userForm.reset();
  }

  saveUser() {
    if (this.user.id) {
      this.usersService
        .updateUser({ ...this.user, ...this.userForm.getRawValue() })
        .subscribe({
          next: () => {
            this.users = this.users.map((item: User) => {
              if (item.id === this.user.id) {
                return {
                  ...item,
                  ...this.userForm.getRawValue()
                };
              } else {
                return item;
              }
            });
            this.userDialog = false;
            this.userForm.reset();
            this.messageService.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'User Updated',
              life: 3000
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Unexpected error',
              detail: 'User update was unsuccessful',
              life: 3000
            });
          }
        });
    } else {
      this.user = {};
      this.usersService
        .createUser({ ...this.userForm.getRawValue() })
        .subscribe({
          next: () => {
            this.users.push({ ...this.userForm.getRawValue() });
            this.userDialog = false;
            this.userForm.reset();
            this.messageService.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'User Updated',
              life: 3000
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Unexpected error',
              detail: 'User update was unsuccessful',
              life: 3000
            });
          }
        });
    }
  }
}
