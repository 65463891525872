import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Competition, ScoringRule } from './competition.types';
import { CoreService } from '../core/core.service';
import { User } from '../users/users.types';

@Injectable({
  providedIn: 'root'
})
export class CompetitionService {
  constructor(
    private httpClient: HttpClient,
    private coreService: CoreService
  ) {}
  public getCompetitions() {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Competitions/0?page=1&pageSize=1000`)
      .pipe(
        map((res: Object) => this.coreService.parseDataFromNode(res)?.items)
      );
  }

  public getNotifications(id: number) {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Competitions/getNews/${id}`)
      .pipe(map((res: Object) => this.coreService.parseDataFromNode(res)));
  }

  public getRankings(id: number) {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Statistics/getLeaderboard/${id}`)
      .pipe(
        map(
          (res: Object) => this.coreService.parseDataFromNode(res)?.leaderboard
        )
      );
  }
  public getAdditionalRankings(id: number) {
    return this.httpClient
      .get(
        `${this.coreService.apiUrl}/api/Statistics/getBreedLeaderboard/${id}`
      )
      .pipe(
        map(
          (res: Object) =>
            this.coreService.parseDataFromNode(res)?.breedLeaderboard
        )
      );
  }
  public getMaxBreedStatistics(id: number) {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Statistics/getStatistics/${id}`)
      .pipe(
        map(
          (res: Object) =>
            this.coreService.parseDataFromNode(res)?.statistics?.breedMax
        )
      );
  }

  public createCompetition(competition: Competition) {
    return this.httpClient.post(
      `${this.coreService.apiUrl}/api/Competitions`,
      competition
    );
  }

  public updateCompetition(competition: Competition, id: number) {
    return this.httpClient.put(
      `${this.coreService.apiUrl}/api/Competitions/${id}`,
      competition
    );
  }

  public getReferees(): Observable<User[]> {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Users/getReferees`)
      .pipe(map((res: Object) => this.coreService.parseDataFromNode(res)));
  }
  public getRefereeDetails(details: string): Observable<User[]> {
    return this.httpClient
      .get(
        `${this.coreService.apiUrl}/api/Users/getReferees?details=${details}`
      )
      .pipe(map((res: Object) => this.coreService.parseDataFromNode(res)));
  }
  public getParticipants(): Observable<User[]> {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/Users/getCompetitors`)
      .pipe(map((res: Object) => this.coreService.parseDataFromNode(res)));
  }

  public getScoringRules(): Observable<ScoringRule[]> {
    return this.httpClient
      .get(`${this.coreService.apiUrl}/api/ScoringRules/0`)
      .pipe(map((res: Object) => this.coreService.parseDataFromNode(res)));
  }

  public getCompetitionCaptures(competitionId: number) {
    return this.httpClient
      .get(
        `${this.coreService.apiUrl}/api/Captures/${competitionId}?page=1&pageSize=10000`
      )
      .pipe(
        map((res: Object) => this.coreService.parseDataFromNode(res)?.items)
      );
  }
  public updateDebate(debate: any, status: string) {
    return this.httpClient.put(
      `${this.coreService.apiUrl}/api/Debates/${debate.id}`,
      { ...debate, status }
    );
  }

  public updateCaptureStatus(captureId: number, status: string) {
    const body = [];
    body.push({
      op: 'replace',
      path: '/status',
      value: status
    });
    return this.httpClient.patch(
      `${this.coreService.apiUrl}/api/Captures/${captureId}`,
      body
    );
  }
  public updateCaptureRejectedDetails(
    captureId: number,
    message: string | null
  ) {
    const body = [];
    body.push({
      op: 'replace',
      path: '/statusDetails',
      value: message
    });
    return this.httpClient.patch(
      `${this.coreService.apiUrl}/api/Captures/${captureId}`,
      body
    );
  }

  public startCompetition(competitionId: number, time: string) {
    const body = [];
    body.push({
      op: 'replace',
      path: '/actualStart',
      value: time
    });
    body.push({
      op: 'replace',
      path: '/actualEnd',
      value: null
    });
    return this.httpClient.patch(
      `${this.coreService.apiUrl}/api/Competitions/${competitionId}`,
      body
    );
  }

  public stopCompetition(competitionId: number, time: string) {
    const body = [];
    body.push({
      op: 'replace',
      path: '/ActualEnd',
      value: time
    });
    return this.httpClient.patch(
      `${this.coreService.apiUrl}/api/Competitions/${competitionId}`,
      body
    );
  }

  public resetCompetition(competitionId: number) {
    return this.httpClient.delete(
      `${this.coreService.apiUrl}/api/Competitions/resetData/${competitionId}`
    );
  }

  public updateCaptureLength(captureId: number, length: string) {
    const body = [];
    body.push({
      op: 'replace',
      path: '/length',
      value: length
    });
    return this.httpClient.patch(
      `${this.coreService.apiUrl}/api/Captures/${captureId}`,
      body
    );
  }
  public updateSpecies(captureId: number, species: string) {
    const body = [];
    body.push({
      op: 'replace',
      path: '/fishType',
      value: species
    });
    return this.httpClient.patch(
      `${this.coreService.apiUrl}/api/Captures/${captureId}`,
      body
    );
  }
}
