import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AdministratorDashboardComponent } from './administrator-dashboard/administrator-dashboard.component';
import { DashboardComponent } from './dashboard.component';
import { RefereeDashboardComponent } from './referee-dashboard/referee-dashboard.component';
import { CompetitionsModule } from '../competitions/competitions.module';
import { UsersModule } from '../users/users.module';

@NgModule({
  declarations: [
    DashboardComponent,
    AdministratorDashboardComponent,
    RefereeDashboardComponent
  ],
  imports: [CommonModule, UsersModule, TranslateModule, CompetitionsModule]
})
export class DashboardModule {}
